import { ApolloClient, InMemoryCache, } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { URL } from "../enviroment";

const httpLink = createUploadLink({ uri: URL.STRAPI_URL + '/graphql' });

const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem('strapi_token');
    return {
        headers: {
            ...headers,
            Authorization: token ? `Bearer ${token}` : "",
        }
    }
});

export const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
});