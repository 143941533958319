import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from '@auth0/auth0-react';
// import { getConfig } from "./config";

// const config = getConfig();

// const providerConfig = {
//   domain: config.domain,
//   clientId: config.clientId,
//   authorizationParams: {
//     redirect_uri: window.location.origin + "/home",
//     ...(config.audience ? { audience: config.audience } : null),
//   },
// };

const { REACT_APP_AUTH0_CLIENT_ID, REACT_APP_AUTH0_DOMAIN, REACT_APP_AUTH0_AUDIENCE } = process.env;

const providerConfig = {
  domain: REACT_APP_AUTH0_DOMAIN,
  clientId: REACT_APP_AUTH0_CLIENT_ID,
  authorizationParams: {
    redirect_uri: window.location.origin + "/home",
    ...(REACT_APP_AUTH0_AUDIENCE ? { audience: REACT_APP_AUTH0_AUDIENCE } : { audience: REACT_APP_AUTH0_AUDIENCE }),
  },
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <Auth0Provider
    {...providerConfig}
  >
    <App />
  </Auth0Provider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
