import React from "react";

const Home = React.lazy(() => import("./components/pages/Home"));
const RSA = React.lazy(() => import("./components/pages/RSA"));
const BG = React.lazy(() => import("./components/pages/Blog"));
const BGC = React.lazy(() => import("./components/pages/CreateBlog"));
const BGE = React.lazy(() => import("./components/pages/EditBlog"));
const BGV = React.lazy(() => import("./components/pages/ViewPost"));
// const AU = React.lazy(() => import("./components/pages/Login"));
const REG = React.lazy(() => import("./components/pages/Register"));
const SET = React.lazy(() => import("./components/pages/Settings"));

const routes = [
   { path: "/home", name: "Home", element: Home },
   { path: "/rsa-google", name: "RSA Google", element: RSA },
   { path: "/blog", name: "Blog", element: BG },
   { path: "/write", name: "Blog", element: BGC },
   { path: "/blog/:id/", name: "View", element: BGV },
   { path: "/post/edit/:id/", element: BGE },
   // { path: "/login", name: "Auth", element: AU },
   { path: "/register", name: "Register", element: REG },
   { path: "/settings", name: "Settings", element: SET }
];

export default routes;
