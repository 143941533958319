import React, { useContext } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import { ADcontext } from "../../context/adContext";
import { DropdowNavBarProfile } from "../atoms/DropdowNavBar";
import { GoogleButton } from "../UI/google-button";

export default function NavBar() {
  const { state } = useContext(ADcontext);

  return (
    <header id="header" className="header p-2">
      <Container className="container-custom">
        <Navbar expand="lg">
          <>
            <Link
              to={"/"}
              className="navbar-brand text-decoration-none logo d-flex align-items-center"
            >
              <img src="assets/img/logo.png" alt="Logo" />
              <span>NextPost</span>
            </Link>
            <Navbar.Toggle
              className="btn-0 py-3 border-0"
              aria-controls="basic-navbar-nav"
            >
              <i
                style={{ color: "#00ca90" }}
                className="fa-xl fa-solid fa-bars"
              ></i>
            </Navbar.Toggle>

            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto">
                {state.isAuth ? (
                  <>
                    <NavLink
                      style={{ color: "#00ca90" }}
                      className="nav-link fw-bold fs-6 text-center"
                      to="/home"
                    >
                      Home
                    </NavLink>

                    <NavLink
                      style={{
                        color: "#00ca90",
                        marginRight: "0px !important",
                      }}
                      className="nav-link fw-bold fs-6 text-center"
                      to="/rsa-google"
                    >
                      NexPost
                    </NavLink>

                    <div className="mb-3 mb-md-0 d-flex justify-content-center">
                      <DropdowNavBarProfile />
                    </div>
                  </>
                ) : (
                  <GoogleButton />
                )}
              </Nav>
            </Navbar.Collapse>
          </>
        </Navbar>
      </Container>
    </header>
  );
}
